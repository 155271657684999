<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="标题" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['title', { rules: [{ required: true, message: '标题不能为空' }] }]" placeholder="请输入标题"></a-input>
      </a-form-item>
      <a-form-item label="描述" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input type="textarea" rows="4" v-decorator="['description',
        { rules: [{ required: true, message: '请输入描述' }] }]" placeholder="请输入描述"></a-input>
      </a-form-item>
      <a-form-item label="上传图片" :label-col="labelCol" :wrapper-col="wrapperCol">
        <qiniu-image-upload
          ref="qiniuImageRef"
          @getImageData="getImageData"
          :document-dir-id="40"
          :multiSelection="true"
          type="edit"
          :items="formItem.images"
        ></qiniu-image-upload>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { ticketInfoVO } from './common/ticketInfoVO'
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'
  import entityCURDCallback from '../../common/mixins/entityCURDCallback'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'

  export default {
    name: 'TicketInfoForm',
    mixins: [entityCURDCallback],
    components: { QiniuImageUpload },
    props: {
      type: {
        type: String,
        default: 'edit'
      }
    },
    mounted () {
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.ticketInfoApi,
        initFormItem: ticketInfoVO(),
        showFormItem: false,
        spinShow: false,
        labelCol: { span: 5 },
        wrapperCol: { span: 17 }
      }
    },
    computed: {},
    created () {
    },
    methods: {
      moment,
      setFields (values) {
        Object.assign(this.formItem, values)
        values.images.forEach((item) => {
          this.formItem.imageIds.push(item.id)
        })
        this.form.setFieldsValue({
          title: this.formItem.ticketInfo.title,
          ticketType: this.formItem.ticketInfo.ticketType,
          description: this.formItem.ticketInfo.description
        })
      },
      setVOFields (values) {
        this.formItem.ticketInfo.title = values.title
        this.formItem.ticketInfo.description = values.description
      },
      /*上传图片回调方法*/
      getImageData (data) {
        this.formItem.imageIds = []
        data.forEach((item) => {
          this.formItem.imageIds.push(item.id)
        })
      }
    }
  }
</script>
