<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">工单处理</h1>
    </div>
    <div slot="extra">
      <a-button style="margin-left: 16px;" icon="plus" @click="handleAdd" type="primary">创建工单</a-button>
    </div>
    <a-card :bordered="false" style="margin-top: 15px;">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="工单名称">
              <a-input v-model="searchParams.title" placeholder="请填写工单名称"/>
            </a-form-item>
            <a-form-item label="工单来源">
              <a-select allowClear
                        optionFilterProp="label"
                        v-model="searchParams.ticketSource"
                        placeholder="请选择"
                        @change="search">
                <a-select-option v-for="item in ticketSource" :key="item.value" :value="item.value" :label="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="处理状态">
              <a-select allowClear
                        optionFilterProp="label"
                        v-model="searchParams.ticketProcessStatus"
                        placeholder="请选择"
                        @change="search">
                <a-select-option v-for="item in ticketProcessStatus" :key="item.value" :value="item.value" :label="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table size="middle"
               :columns="columns"
               :dataSource="tableData"
               :loading="tableLoading"
               :pagination="pagination"
               @change="tableChange"
               :customRow="handleView"
               :rowKey="(record) => record.id">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="ticketTitle" slot-scope="text, record">
          <a-tooltip>
            <template slot="title">
              {{record.title}}
            </template>
            {{record.title}}
          </a-tooltip>
        </template>
        <template slot="description" slot-scope="text, record">
          <a-tooltip>
            <template slot="title">
              {{record.description}}
            </template>
            {{record.description}}
          </a-tooltip>
        </template>
        <template slot="ticketType" slot-scope="text, record">
          <span v-if="record.ticketType==='INSPECT'">巡检</span>
          <span v-if="record.ticketType==='REPORT'">上报</span>
          <span v-if="record.ticketType==='CAR'">车辆</span>
          <span v-if="record.ticketType==='OTHER'">其他</span>
        </template>
        <template slot="ticketSource" slot-scope="text, record">
          <a-tag v-if="record.ticketSource==='MANUAL'" color="#CD8500">人工创建</a-tag>
          <a-tag v-if="record.ticketSource==='AUTO'" color="#B452CD">自动生成</a-tag>
          <a-tag v-if="record.ticketSource==='REPORT'" color="#008B8B">微信上报</a-tag>
        </template>
        <template slot="ticketProcessStatus" slot-scope="text, record">
          <a-tag v-if="record.ticketProcessStatus==='UN_PROCESS'" color="blue">待处理</a-tag>
          <a-tag v-if="record.ticketProcessStatus==='PROCESSING'" color="#15da8e"> {{record.currentUser.name}}处理中</a-tag>
          <a-tag v-if="record.ticketProcessStatus==='PROCESSED'" color="#1874CD">已完成</a-tag>
        </template>
        <template slot="startProcessTime" slot-scope="text, record">
          <span>{{record.startProcessTime | formatDate}}</span>
        </template>
        <template slot="endProcessTime" slot-scope="text, record">
          <span>{{record.endProcessTime | formatDate}}</span>
        </template>
        <template slot="action" slot-scope="text, record">
          <a v-if="record.ticketProcessStatus==='UN_PROCESS'" @click.stop="handleAssign(record.id)">派单</a>
          <a-divider v-if="record.ticketProcessStatus==='UN_PROCESS'" type="vertical"/>
          <a v-if="record.ticketProcessStatus==='PROCESSING'&&record.currentUser.id===currentUser.id" @click.stop="handleClick(record.id)">处理</a>
          <a-divider v-if="record.ticketProcessStatus==='PROCESSING'&&record.currentUser.id===currentUser.id" type="vertical"/>
          <a @click.stop="handleDelete(record.id)">删除</a>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal title="创建工单"
             @ok="handleAddOk"
             @cancel="closeAddModal"
             :visible="addModal"
             type="add">
      <ticket-info-form ref="addRef" @addSuccess="addSuccess" type="add" @addError="addError"></ticket-info-form>
    </a-modal>
    <!--修改Modal-->
    <a-modal
      title="修改工单"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :confirmLoading="editLoading">
      <ticket-info-form ref="editRef" type="edit" @editSuccess="editSuccess" @editError="editError"></ticket-info-form>
    </a-modal>
    <!--指派Modal-->
    <a-modal title="指派工单" v-model="assignModal" width="560px">
      <assign ref="assignRef" @assignSuccess="assignSuccess" @assignError="assignError"></assign>
      <template slot="footer">
        <a-button :loading="assignLoading" @click="assignOk" type="primary" style="margin-right: 5px;">派单</a-button>
        <a-button @click="closeAssignModal">取消</a-button>
      </template>
    </a-modal>
    <!--处理Modal-->
    <a-modal title="工单处理" v-model="handleModal" width="560px">
      <handle ref="handleRef" @handleSuccess="handleSuccess" @handleError="handleError"></handle>
      <template slot="footer">
        <a-button :loading="handleLoading" @click="handleOk" type="primary" style="margin-right: 5px;">处理</a-button>
        <a-button @click="closeHandleModal">取消</a-button>
      </template>
    </a-modal>
    <!--详情Modal-->
    <a-drawer
      title="工单详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="550px"
      :visible="detailDrawer"
      :mask="false">
      <ticket-info-detail ref="viewRef"></ticket-info-detail>
    </a-drawer>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { columns, ticketSource, ticketProcessStatus } from './common/common'
  import entityCRUDWithCopy from '../../common/mixins/entityCRUDWithCopy'
  import TicketInfoForm from './Form'
  import Assign from './Assign'
  import Handle from './Handle'
  import TicketInfoDetail from './Detail'
  import moment from 'moment'

  export default {
    name: 'ticketInfoList',
    components: { PageLayout, TicketInfoForm, Assign, Handle, TicketInfoDetail },
    mixins: [entityCRUDWithCopy],
    filters: {
      formatDate (date) {
        if (date) {
          return moment(date).format('YYYY-MM-DD HH:mm:ss')
        } else {
          return '~'
        }
      }
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.ticketInfoApi,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          title: '',
          ticketSource: '',
          ticketProcessStatus: ''
        },
        initColumns: columns(),
        ticketSource: ticketSource(),
        ticketProcessStatus: ticketProcessStatus(),
        ticketId: null,
        //处理工单
        handleModal: false,
        handleLoading: false,
        //指派
        assignModal: false,
        assignLoading: false
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    created () {
    },
    methods: {
      moment,
      /*******工单处理开始*******/
      handleClick (id) {
        this.handleModal = true
        this.ticketId = id
      },
      handleOk () {
        this.$nextTick(() => {
          this.$refs.handleRef.handle(this.ticketId)
        })
      },
      closeHandleModal () {
        this.handleModal = false
      },
      handleSuccess () {
        this.handleModal = false
        this.$message.success('处理完成')
        this.search()
      },
      handleError () {
        this.handleModal = false
        this.$message.warning('处理失败')
      },
      /*******指派工单开始*******/
      handleAssign (id) {
        this.assignModal = true
        this.ticketId = id
      },
      assignOk () {
        this.$nextTick(() => {
          this.$refs.assignRef.assign(this.ticketId)
        })
      },
      assignSuccess () {
        this.assignModal = false
        this.$message.warning('派单完成')
        this.search()
      },
      assignError () {
        this.assignModal = false
        this.$message.warning('派单失败')
      },
      closeAssignModal () {
        this.assignModal = false
      },
      /*******详情开始*******/
      detailClose () {
        this.detailDrawer = false
      },
      handleView (record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.$refs.viewRef.loadData(record.id)
              })
            }
          }
        }
      }
    }
  }
</script>

<style scoped></style>
