import { render, staticRenderFns } from "./Handle.vue?vue&type=template&id=719b152d&scoped=true&"
import script from "./Handle.vue?vue&type=script&lang=js&"
export * from "./Handle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "719b152d",
  null
  
)

export default component.exports